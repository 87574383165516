import { createContext, useContext, useMemo } from 'react';
import { useTheme } from '@chakra-ui/react';
import type { ChakraTheme } from './types';
import type { ReactNode } from 'react';

const ThemeContext = createContext<Record<
  keyof ChakraTheme['__cssMap'],
  string
> | null>(null);

export const ChakraThemeProvider = ({ children }: { children: ReactNode }) => {
  const theme = useTheme();

  const themeMemo = useMemo(() => {
    return Object.entries(theme.__cssMap).reduce((theme, [key, value]) => {
      return {
        ...theme,
        [key]: value.value,
      };
    }, {}) as unknown as Record<keyof ChakraTheme['__cssMap'], string>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeContext.Provider value={themeMemo}>{children}</ThemeContext.Provider>
  );
};

export const useChakraTheme = () => {
  const theme = useContext(ThemeContext);
  if (!theme) {
    throw Error('Theme not found');
  }
  return theme;
};
